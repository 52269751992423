import {
  StaffResponse,
  StaffsResponse,
  StaffBasicWithRelationsResponse,
} from 'src/models/api/workplaceMasters/staffResponse'
import { BudgetGroup, budgetGroupResponseToBudgetGroup } from 'src/models/budgetGroup'
import { BudgetGroupResponse } from 'src/models/api/budgetGroupResponse'

export type Staff = Omit<StaffResponse, 'budget_group'> & {
  budget_group: BudgetGroup
}

export function staffResponseToStaff(staffResponse: StaffResponse): Staff {
  return Object.entries(staffResponse).reduce((acc: Partial<Staff>, [k, v]) => {
    if (k === 'budget_group') {
      acc.budget_group = budgetGroupResponseToBudgetGroup(v as BudgetGroupResponse)
    } else {
      acc[k as keyof Staff] = v
    }
    return acc
  }, {}) as Staff
}

export function staffsResponseToStaffs(staffsResponse: StaffsResponse): Staff[] {
  return staffsResponse.map<Staff>(e => staffResponseToStaff(e))
}

export function staffBasicWithRelationsResponseToStaffs(response: StaffBasicWithRelationsResponse): Staff[] {
  // responseを元にStaff[]の各要素を作成する
  return response.staffs.map<Staff | undefined>((staffBasicResponse) => {
    // 前提:APIのレスポンスサイズを小さくするためresponse内でstaffとそれ以外のマスタを分離した構造にしている.
    // 型の都合もあり一応一つずつ調べてはいるものの、staffが持つidに紐づくマスタは全てresponse内に入っているはず.
    const staffAgency = response.staff_agency_map[staffBasicResponse.staff_agency_id]
    if (!staffAgency) return undefined

    const staffLabel = response.staff_label_map[staffBasicResponse.staff_label_id]
    if (!staffLabel) return undefined

    const budgetGroup = response.budget_group_map[staffBasicResponse.budget_group_id]
    if (!budgetGroup) return undefined

    return {
      ...staffBasicResponse,
      budget_group: budgetGroup,
      staff_agency: staffAgency,
      staff_label: staffLabel
    }
  }).filter((e): e is Staff => e !== undefined)
}
