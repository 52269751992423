<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import * as C from 'src/consts'
import masterMixin from 'src/mixins/masterMixin'
import dateTimeMixin from 'src/mixins/dateTimeMixin'
import notificationMixin from 'src/mixins/notificationMixin'
import workplaceApi from 'src/apis/workplace'
import timingControlMixin from 'src/mixins/timingControlMixin'
import routeMixin from 'src/mixins/routeMixin'
import { setPageName } from 'src/hooks/displayPageNameHook'
import windowMixin from 'src/mixins/windowMixin'

export default {
  data() {
    return {
      validations: this.getValidationMap(),

      workplaces: [],
      userRoles: {},
      invitedWorkplace: {},
      workplaceToCreate: {},

      showAcceptInvitationModal: false,
      showCreateWorkplaceModal1: false,
      showCreateWorkplaceModal2: false,

      isLoading: true,

      userRoleConst: {
        wOwner: C.USER_WORKPLACE_ROLE_W_OWNER,
        wLeader: C.USER_WORKPLACE_ROLE_W_LEADER,
        wMember: C.USER_WORKPLACE_ROLE_W_MEMBER,
      },
      workplaceFilterText: '',
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
      userEmail: 'email',
    }),
    hasWorkplaces() {
      return this.workplaces.length > 0
    },
    hasError() {
      return this.errors.any()
    },
    isKurandoSupportUser() {
      return this.userEmail === 'support@kurando.io'
    },
    filteredWorkplaces() {
      if (!this.workplaceFilterText) {
        return this.workplaces.slice()
      }

      const filterText = this.workplaceFilterText.toLowerCase()

      return this.workplaces.filter(workplace => {
        const workplaceName = workplace.name.toLowerCase()
        const companyName = workplace.company_name.toLowerCase()
        return workplaceName.includes(filterText) || companyName.includes(filterText)
      })
    },
  },
  mixins: [masterMixin, dateTimeMixin, notificationMixin, timingControlMixin, routeMixin, windowMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = {
        required: true,
        max: 255,
      }
      return {
        name: ruleStr,
        address: { max: 255 },
      }
    },
    clearErrors() {
      this.$validator.reset()
    },
    prepareMasters() {
      const lovs = window.master.lovs
      this.userRoles = lovs.user_role.map_inuse
    },
    async getWorkplaces() {
      this.isLoading = true
      const { data } = await workplaceApi.index()
      data.forEach(e => {
        e.isUserWorkplaceStatusPending =
            e.user_workplace_status === C.USER_WORKPLACE_STATUS_PENDING
      })
      this.workplaces = data
      this.isLoading = false
    },
    async acceptInvitation() {
      try {
        await workplaceApi.acceptWorkplace(this.invitedWorkplace.id)
        this.getWorkplaces()
        this.closeAcceptInvitationModal()
        this.notifySuccess1('センター共有を承諾しました')
        this.reloadOpener()
      } catch (err) {
        const msg = 'センター共有の承諾に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00001, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    async declineInvitation() {
      try {
        await workplaceApi.declineWorkplace(this.invitedWorkplace.id)
        this.getWorkplaces()
        this.closeAcceptInvitationModal()
        this.notifySuccess1('センター共有を拒否しました')
      } catch (err) {
        const msg = 'センター共有の拒否に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00002, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    closeAcceptInvitationModal() {
      this.invitedWorkplace = {}
      this.showAcceptInvitationModal = false
    },
    async openCreateWorkplaceModal2() {
      const isValid = await this.$validator.validateAll('workplace1')
      if (!isValid) { return }
      // TODO 次期開発時に修正する
      // this.showCreateWorkplaceModal1 = false
      // this.showCreateWorkplaceModal2 = true

      this.createWorkplace()
    },
    async createWorkplace() {
      try {
        await workplaceApi.create(this.workplaceToCreate)
        await this.$store.dispatch('user/getMeRefresh')
        await this.getWorkplaces()
        this.closeCreateWorkplaceModal()
        this.notifySuccess1('センターを新規作成しました')
      } catch (err) {
        const msg = 'センターの作成に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00003, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    closeCreateWorkplaceModal() {
      this.workplaceToCreate = {}
      this.clearErrors()
      this.showCreateWorkplaceModal1 = false
      this.showCreateWorkplaceModal2 = false
    },
    async openAcceptInvitationModal(wp) {
      const { data } = await workplaceApi.show(wp.id)
      this.invitedWorkplace = data
      this.showAcceptInvitationModal = true
    },
    async goToNextPage(evt, wp) {
      // センターをセットして次の画面へ
      await this.$store.dispatch('workplace/setWorkplace', wp)

      const routeObj = {
        name: 'SettingsSelect',
        params: { workplaceId: wp.id }
      }
      this.routerShowNextPage(evt, routeObj)
    },
    async onWorkplaceClick(evt, wp) {
      if (wp.isUserWorkplaceStatusPending) {
        await this.openAcceptInvitationModal(wp)
      } else {
        await this.goToNextPage(evt, wp)
      }
    },
    makeFuncGated() {
      this.acceptInvitation = this.makeAsyncFuncGated(this.acceptInvitation)
      this.declineInvitation = this.makeAsyncFuncGated(this.declineInvitation)
      this.createWorkplace = this.makeAsyncFuncGated(this.createWorkplace)
    },
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, 'センター選択')
    await this.ensureUserAndMasters()
    this.prepareMasters()
    await this.getWorkplaces()
    // query parameter "mode" will be passed from other SP (Logimeter, Logiboard).
    // センター作成時
    if (this.$route.query.mode === 'create' && this.isKurandoSupportUser) {
      this.showCreateWorkplaceModal1 = true
    }
    // センター承諾時
    if (this.$route.query.mode === 'accept' && this.$route.query.workplace_id) {
      const wp = this.workplaces.find(wp => wp.id === parseInt(this.$route.query.workplace_id))
      if (wp) {
        // wp would be 'undefined' if invalid workplace_id is passed
        await this.openAcceptInvitationModal(wp)
      }
    }
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
